import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, useTrail, animated } from 'react-spring';

import { font, colors, margin, breakpoints } from 'src/modules/styles';
import { Logo } from 'src/modules/image';
import { Container } from 'src/modules/layout';
import { useTransitionAnimation } from 'src/modules/animations';
import { SEO } from 'src/modules/seo';

const S = {
  Container: styled(Container)`
    overflow-x: hidden;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  TextWrapper: styled(animated.div)`
    mix-blend-mode: difference;
    height: 45px;

    ${breakpoints.s`
      height: 75px;
    `}
  `,
  Text: styled.span`
    z-index: 1;
    position: relative;
    ${font.size.m}
    ${font.weight.bold}
    color: ${colors.white};

    ${breakpoints.s`
      ${font.size.xl}
    `}
  `,
  Logo: styled(Logo)`
    ${margin.bottom.s};
    mix-blend-mode: difference;

    path {
      fill: white;
    }
  `,
};

const A = {
  Logo: animated(S.Logo),
};

const textTransitionAnimations = [
  {
    status: ['entering', 'entered'],
    styles: {
      delay: 300,
      opacity: 1,
      x: 0,
      height: 45,
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 0,
      x: 10,
      height: 0,
    },
  },
];

const logoTransitionAnimations = [
  {
    status: ['entering', 'entered'],
    styles: {
      delay: 300,
      opacity: 1,
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 0,
    },
  },
];

const text = ['Sermaidis', 'Press', 'Athens'];

const HomePage = ({ transitionStatus }) => {
  const trail = useTrail(
    text.length,
    useTransitionAnimation(
      {
        config: { mass: 5, tension: 2000, friction: 200 },
        from: {
          opacity: 0,
          x: 10,
          height: 0,
        },
      },
      textTransitionAnimations,
      transitionStatus,
    ),
  );

  const logoAnimatedStyles = useSpring(
    useTransitionAnimation(
      {
        from: { opacity: 0 },
      },
      logoTransitionAnimations,
      transitionStatus,
    ),
  );

  return (
    <>
      <S.Container>
        <SEO />
        <A.Logo style={logoAnimatedStyles} />
        {trail.map(({ x, height, ...rest }, index) => (
          <S.TextWrapper
            key={text[index]}
            style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}
          >
            <S.Text style={{ height }}>{text[index]}</S.Text>
          </S.TextWrapper>
        ))}
      </S.Container>
    </>
  );
};

HomePage.propTypes = {
  transitionStatus: PropTypes.string.isRequired,
};

export default HomePage;
